/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.CheckoutDeliveryOption {
    &-EstimatedDelivery {
        margin: 0;
        font-size: 14px;
        opacity: 0.5;
    }

    .Field {
        &_type {
            &_radio {
                margin-block-start: 3px;
            }
        }
    }
}
