/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * Mageplaza Gift Wrap compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

.GiftWrapCheckoutSection {
    padding-block: 16px;
    border-top: 1px solid rgba(var(--black-rgb-color), 0.15);
    padding-inline-end: 10px;

    &-Header {
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 1.125rem;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        margin-block-end: 8px;
    }

    &-Description {
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.250rem;
        margin-block-end: 0;
    }

    &-Content {
        display: flex;
        gap: 16px;
    }

    &-Info {
        flex-grow: 1;
    }

    &-Note {
        display: flex;
        justify-content: space-between;
        margin-block-end: 8px;
        gap: 8px;
    }

    &-NotePrice {
        word-break: keep-all;
        white-space: nowrap;
    }

    .Image {
        width: 62px;
        min-width: 62px;
        height: 77px;
        padding: 0;
    }

    .ProductActions {
        &-GiftWrapButton {
            display: block;
            width: 100%;
            padding: 10px;
            border: 1px solid $default-primary-base-color;
            transition: 0.25s ease-out;
            margin-block-start: 12px;

            &:hover {
                background: $default-primary-base-color;

                span {
                    color: $white;
                }
            }

            span {
                font-weight: 600;
                font-size: 0.875rem;
                line-height: 1.125rem;
                letter-spacing: 0.08em;
                text-transform: uppercase;
            }
        }
    }
}
