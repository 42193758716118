/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/* stylelint-disable declaration-no-important */
@mixin checkout-mobile {
    @media (max-width: 810px) and (-webkit-min-device-pixel-ratio: 1) {
        @content;
    }
}

@mixin checkout-after-mobile {
    @media (min-width: 811px) and (-webkit-min-device-pixel-ratio: 1) {
        @content;
    }
}

@mixin checkout-tablet {
    @media (min-width: 811px)
        and (max-width: 1199px)
        and (-webkit-min-device-pixel-ratio: 1) {
        @content;
    }
}

.Checkout {
    display: flex;
    align-items: unset;
    width: 100%;
    height: 100%;
    min-height: 100%;

    input {
        font-size: 1rem;
    }

    .Field {
        textarea {
            font-size: 0.875rem;
        }

        &-CheckboxLabel {
            font-size: 0.75rem;
        }
    }

    @include checkout-after-mobile {
        flex-direction: row;
    }

    @media (min-width: 744px) and (max-width: 810px) {
        flex-direction: column;
    }

    &-GuestEmail {
        margin-block-end: 52px;

        .Button {
            &_likeLink {
                text-transform: unset;
                font-weight: 400;
                text-decoration: underline;

                &:hover,
                &:focus {
                    text-decoration: none;
                    color: $black;
                }
            }
        }

        .MyAccountOverlay {
            &-Buttons {
                padding-block-start: 8px;
            }
        }

        .Field {
            margin-block-start: 0;

            &-Wrapper_name_newsletter {
                margin-block-start: 12px;
            }

            &-Wrapper_name_password {
                margin-block-start: 8px;
            }

            &-Wrapper_name_password,
            &-Wrapper_name_email {
                input {
                    padding-block-end: 6px;
                }
            }

            &-CheckboxLabel {
                gap: 12px;
                font-size: 1rem;
                font-weight: 400;
                color: $black;

                &:hover {
                    color: $black;
                }
            }

            &-LabelContainer {
                display: none;
            }
        }

        input[type="email"],
        input[type="password"],
        input[type="text"] {
            width: 100%;
            background-color: var(--light-beige);
            padding-block-start: 1.625rem;
            padding-block-end: 0.375rem;
            padding-inline-start: 16px;
            height: auto;
            min-height: 52px;
        }

        p {
            font-size: 0.875rem;
            font-weight: 400;
            color: $black;
            opacity: .5;
            line-height: 126%;
            margin-block-start: 8px;
            margin-block-end: 0;
        }
    }

    @include checkout-mobile {
        flex-direction: column;
        height: 100vh;
    }

    &-Header {
        display: inline-flex;
        width: 100%;
        margin-block: 0px;
        padding-block: 24px;
        background-color: $white;

        @include before-desktop {
            justify-content: center;
        }

        @include checkout-tablet {
            width: 100vw;
            z-index: 101;
        }

        @include desktop {
            max-width: 404px;
            margin-inline-end: 32px;
            margin-inline-start: auto;
        }
    }

    &-LogoWrapper {
        display: block;
        width: 162px;
        height: 25px;

        .Image {
            background: none;
        }
    }

    &-BackButton {
        position: absolute;
        inset-inline-start: 16px;
        inset-block-start: 30px;
        cursor: pointer;
        width: 16px;
        height: 16px;
        z-index: 1;

        @include checkout-tablet {
            inset-inline-start: 32px;
            z-index: 201;
        }

        @include desktop {
            inset-inline-start: 60px;
        }
    }

    &-Divider {
        position: absolute;
        width: 100%;
        height: 1px;
        inset-inline-start: 0px;
        inset-block-start: 72px;
        background: $black;
        opacity: 0.16;

        @include checkout-tablet {
            width: 100vw;
            z-index: 101;
        }
    }

    &-Container {
        margin-block: 0;
        min-height: 100%;
        display: flex;
        margin-inline-start: auto;
        flex-direction: column;
        background-color: $white;

        @include checkout-after-mobile {
            width: 50vw;
        }
    }

    &-Step {
        @include checkout-tablet {
            padding-inline-start: 32px;
            padding-inline-end: 12px;
        }

        @include checkout-after-mobile {
            max-width: 25.25rem;
            margin-inline-start: auto;
        }

        padding-block-start: 24px;
    }

    &-Wrapper {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;

        &_isSummary {
            min-height: 100vh;
            background-color: var(--light-beige);

            @include desktop {
                width: 50vw;
            }

            @include checkout-tablet {
                margin-inline-start: 12px;
                margin-inline-end: 32px;
                padding-block-start: 63px;
            }
        }
    }

    .Field-Wrapper_isDisabled {
        opacity: 0.4;

        div[role="button"] {
            cursor: not-allowed;
        }

        select {
            cursor: not-allowed;
        }

        input,
        .FieldSelect-Clickable {
            cursor: not-allowed;
        }
    }

    &-Icon {
        @include checkout-mobile {
            width: 62px;
            height: 36px;
        }
    }

    &-IconsWrapper {
        justify-content: flex-start;
        gap: 6px;
        flex-wrap: wrap;
    }

    &-LinksAndIconsWrapper {
        width: 100%;
        padding-inline: 0px;
        margin-inline-start: auto;
        align-self: flex-end;

        @include checkout-tablet {
            padding-inline-start: 32px;
            padding-inline-end: 12px;
        }

        @include checkout-after-mobile {
            max-width: 25.25rem;
        }
    }

    &-LinksAndIcons {
        width: 100%;

        @include checkout-mobile {
            padding-block-end: 32px;
        }

        @include checkout-tablet {
            padding-block-end: 32px;
        }
    }

    &-Link {
        &:visited {
            color: var(--link-color);
        }

        text-decoration: none;
        font-size: 0.875rem;
        color: var(--primary-base-color);

        &:hover {
            color: var(--selected-label-color);
        }
    }

    .ExpandableContent {
        padding-block-end: 16px;

        &-Heading {
            line-height: 1.5rem;

            @include mobile {
                color: $black;
                text-transform: unset;
                letter-spacing: 0;
            }
        }

        &_isExpanded:last-child {
            border-bottom: none;
        }

        &-Content {
            overflow: hidden !important;

            @include checkout-mobile {
                overflow: hidden !important;
            }

            @include checkout-tablet {
                overflow: hidden !important;
            }

            &_isContentExpanded {
                overflow: initial !important;
            }
        }
    }

    .ExpandableContent-ButtonWrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        button {
            font-size: 14px;
            white-space: nowrap;
            text-decoration: underline;
            color: $default-primary-base-color;

            &:hover {
                cursor: pointer;
                text-decoration: none;
                color: $black;
            }
        }
    }

    &-ExpandableContent {
        &_isShipping {
            .ExpandableContent-ButtonWrapper {
                margin-block-end: 8px;
            }
        }
    }

    &-ButtonWrapper {
        margin-block-start: 24px;
    }

    &-ContentWrapper {
        @include checkout-after-mobile {
            display: flex;
            flex-direction: row;
        }

        @media (min-width: 811px) and (max-width: 810px) {
            display: flex;

            .Checkout {
                &-Step {
                    padding-inline: 0px;
                    margin-inline: 0px;
                }

                &-Wrapper {
                    margin-inline: 0px;
                    padding-inline-start: 32px;
                    padding-inline-end: 12px;

                    &_isSummary {
                        margin-inline: 0px;
                        padding-inline-start: 12px;
                        padding-inline-end: 32px;
                    }
                }

                &-LinksAndIconsWrapper {
                    padding-inline: 0px;
                    margin-inline: 0px;
                }
            }
        }
    }

    &-SignInWrapper {
        display: flex;
        flex-direction: row;
        margin-block-start: 8px;

        p {
            margin-block-start: 0px;
            opacity: unset;
        }
    }

    &-SignInLink {
        text-transform: unset;
        font-weight: 400;
        text-decoration: underline;
        color: var(--primary-base-color);
        line-height: 126%;
        font-size: 0.875rem;
        padding-inline-start: 3px;

        &:hover,
        &:focus {
            text-decoration: none;
            color: $black;
            cursor: pointer;
        }
    }

    #forgot-password-success {
        margin-block-start: 0px;
        opacity: unset;
    }

    .PrivacySetting-Link {
        span {
            font-size: 1rem;
            font-weight: 400;
            line-height: 126%;
            transition: background-size 1s ease, color 0.3s ease;
            width: calc(100% + 1px); // IE fix
            padding-block-end: 2px;
            background-image: linear-gradient(transparent calc(100% - #{1px}), $black 2px);
            background-repeat: no-repeat;
            background-size: 0% 100%;

            &:hover {
                color: $black;
                background-size: 100% 100%;
            }
        }

        &::before,
        &::after {
            display: none;
        }
    }

    .CheckoutOrderSummary {
        border-bottom: 0px;

        @include checkout-after-mobile {
            max-width: 26.625rem;
            margin-inline-end: auto;
        }

        &-ButtonWrapper {
            @include checkout-tablet {
                display: block;
                position: relative;
                inset: 0;
                padding: 0;
                background: none;
                border: none;
            }

            @include checkout-mobile {
                display: block;
                position: relative;
                inset: 0;
                padding: 0;
                background: none;
                border: none;
            }
        }

        &_isExpanded {
            @include checkout-after-mobile {
                height: unset;
                padding-inline: 0px;
                margin-block-start: 0px;
                margin-block-end: 16px;
            }
        }

        @include checkout-mobile {
            margin-block-end: 0px;
            height: fit-content;
        }

        .ExpandableContent {
            @include checkout-mobile {
                padding: 0px;

                &-Content {
                    padding-inline: 16px;

                    &_isContentExpanded {
                        padding-block-end: 16px;
                    }
                }

                &-Button {
                    pointer-events: all !important;
                }

                &-ButtonWrapper {
                    display: flex;
                    position: sticky;
                    z-index: 100;
                    inset-block-start: 0px;
                    padding: 16px;
                    padding-block-start: 15px;
                    background: var(--light-beige);
                }
            }
        }
    }

    .CheckoutDeliveryOption {
        &-Button {
            font-size: 0.875rem;
        }

        &-EstimatedDelivery {
            font-size: 0.875rem;
        }
    }
}
