/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.CheckoutAddressForm {
    textarea {
        width: 100%;
        border: 1px solid var(--color-border-textarea);
        padding-inline-start: 16px;
        resize: vertical;
    }

    .Field {
        &.Field_type_textarea {
            &.Field_isValid {
                textarea {
                    border-color: var(--color-border-textarea);
                }
            }
        }

        &Select-Select {
            font-size: 1rem;
        }
    }

    .CheckmarkIcon {
        display: none;
    }

    &_isNewAddress {
        margin-block-start: 16px;

        input {
            width: 100%;
        }

        .FieldForm-Fields {
            display: block;

            .Form {
                display: grid;
                grid-template-columns: 60% 40%;
                column-gap: 0;
                row-gap: 8px;

                .Field-Wrapper_name {
                    &_shipping_firstname,
                    &_firstname,
                    &_shipping_lastname,
                    &_lastname,
                    &_shipping_telephone,
                    &_telephone,
                    &_country_id,
                    &_country_code,
                    &_shipping_region_id,
                    &_shipping_street_0,
                    &_street,
                    &_street_0 {
                        grid-column-start: 1;
                        grid-column-end: span 2;
                    }

                    &_shipping_postcode,
                    &_postcode {
                        margin-inline-start: 8px;
                    }
                }

                .FieldGroup-Wrapper_zipCity {
                    grid-column-start: 1;
                    grid-column-end: span 2;
                    display: grid;
                    grid-template-columns: 60% 40%;
                    column-gap: 0;

                    .Field-Wrapper_name_postcode {
                        margin-inline-start: 8px;
                    }
                }

                .Button {
                    grid-column-start: 1;
                    grid-column-end: span 2;
                }
            }
        }
    }

    .CheckoutAddressBook & {
        .FieldForm-Fields {
            display: grid;
            grid-template-columns: 60% 40%;
            column-gap: 0;

            .Field-Wrapper_name {
                &_shipping_firstname,
                &_firstname,
                &_shipping_lastname,
                &_lastname,
                &_shipping_telephone,
                &_telephone,
                &_country_id,
                &_shipping_region_id,
                &_shipping_street_0,
                &_street_0 {
                    grid-column-start: 1;
                    grid-column-end: span 2;
                }

                &_shipping_postcode,
                &_postcode {
                    margin-inline-start: 8px;
                }
            }

            .FieldGroup-Wrapper_zipCity {
                .FieldGroup {
                    display: grid;
                    grid-template-columns: 60% 40%;
                    column-gap: 0;

                    .Field-Wrapper_name_postcode {
                        margin-inline-start: 8px;
                    }
                }
            }
        }
    }

    .FieldForm-Fields {
        .Field-Wrapper {
            .Field_isValid {
                .Field-InputWrapper {
                    .FieldSelect-Clickable select {
                        padding-block: 1.625rem 0.375rem;
                        height: auto;
                        line-height: 1.25rem;

                        .FieldSelect-AltPlaceholder {
                            inset-block-start: 0.375rem;
                        }
                    }
                }
            }

            .Field-InputWrapper {
                .FieldSelect-Clickable_hasDefault select {
                    padding-block: 1.625rem 0.375rem;
                    height: auto;
                }

                .FieldSelect-Clickable {
                    select {
                        height: auto;
                        padding-block: 0.875rem;
                    }
                }

                input {
                    padding-block: 1.625rem 0.375rem;
                    height: auto;

                    &[type="checkbox"] {
                        padding-block: 1.625rem 0.375rem;
                        height: 20px;
                    }
                }
            }

            .Field-PlaceHolder {
                line-height: 1.125rem;
            }
        }
    }

    .Checkout-ButtonWrapper {
        margin-block-start: 24px;
    }

    .CheckoutAddressForm {
        margin-block-end: 8px;
    }
}
