/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.CheckoutPayment {
    list-style-type: none;

    &-Button {
        font-size: 1rem;
    }

    &-Heading {
        display: flex;
        flex-flow: column;
        align-items: flex-start;
    }

    .CheckBoxIconControl {
        min-width: auto;
    }

    .Field {
        input {
            &:checked + .CheckBoxIconControl {
                border-color: var(--color-black);
            }
        }

        &-LabelContent {
            font-size: 1rem;
        }
    }
}
