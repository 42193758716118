/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.CheckoutAddressTable {
    @include desktop {
        display: flex;
    }

    & + .CheckoutAddressTable {
        margin-block-start: 12px;
    }

    & + .CheckoutShippingForm-CheckboxWrapper {
        margin-block-start: 8px;
    }

    & + .CheckoutAddressBook {
        margin-block-start: 12px;
    }

    &-Content {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        gap: 8px;

        div {
            line-height: 20.16px;
            font-size: 1rem;
            font-weight: 400;
        }
    }

    &-SelectedState {
        @include desktop {
            display: block;
        }
    }
}
