/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.CheckoutShippingForm {
    &-CompanyFields {
        margin-block-start: 12px;
    }

    &-BillingAddressHeading {
        display: flex;
        justify-content: space-between;
        align-items: center;

        button {
            height: min-content;
            text-decoration: underline;
            color: $default-primary-base-color;
            font-size: 14px;

            &:hover {
                cursor: pointer;
                text-decoration: none;
                color: $black;
            }
        }

        h3 {
            text-transform: uppercase;
            font-weight: 600;
            margin-block: 16px;
            font-size: 0.875rem;
        }
    }

    &-CheckboxWrapper {
        + .CheckoutShippingForm-CheckboxWrapper {
            margin-block-start: 8px;
        }

        .Field-LabelContent {
            color: $black;
            opacity: 0.5;
            font-size: 1rem;
        }

        &_isChecked {
            .Field-LabelContent {
                opacity: 1;
            }
        }
    }
}
