/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
// Colors
$white: #fff;
$black: #1d1e1c;
$light-grey: #F5F5F5;
$text-light-grey: #8E8E8D;
$default-primary-base-color: #4c6f68;
$default-primary-dark-color: $black;
$default-primary-light-color: #a5bcb1;
$default-secondary-base-color: #eee;
$default-secondary-dark-color: darken($default-secondary-base-color, 15%);
$default-secondary-light-color: lighten($default-secondary-base-color, 15%);
$input-border-color: #BEBEBE;
$giftwrap-popup-notification-background-color: #A1B1C8;
$philippine-grey-color: #8E8E8D;
$gray-background-color: #F0EEEF;
$blue-banner-color: #A1B1C8;
$conch: #9FB2AE;
$fill-color-offline: #A5BCB1B3;


// Font families
$font-arial: "Arial";
$font-helvetica: "Helvetica";
$font-helvetica-neue: "Helvetica Neue";
$font-muli: 'Muli', sans-serif;
$font-open-sans: "Open Sans";
$font-sans-serif: "sans-serif";
$font-Klavika: 'Klavika Basic', sans-serif;
$font-Prospectus-Pro-Cyrillic-Italic: "Prospectus Pro Cyrillic Italic", sans-serif;
$font-Roboto-Condensed: 'Roboto Condensed', sans-serif;

// Font Sizes
$font-standard-size: 62.5%;
$font-large-size: 1.25rem;
$font-medium-large-size: 1.125rem;
$font-medium-size: 1rem;
$font-small-size: 0.875rem;

// Font Weights
$fw-bold: 700;
$fw-medium: 600;
$fw-light: 400;

// Line Heights
$lh-extra-large: 1.625rem;
$lh-medium-large: 1.25rem;
$lh-medium: 1.125rem;
$location-selected-location-bg-color: #F0EEEF;
$location-selected-label-color: #1D1E1C;
