/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * Mageplaza Gift Wrap compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

.GiftWrapCheckoutSummarySection {
    &-ExpandableContent {
        border-bottom: 1px solid var(--primary-divider-color);
        border-top: none;
        // ^^^ because it's the first item after product list so we don't need border unless it's desktop size

        @include desktop {
            margin-block-start: 12px;
            border-top: 1px solid var(--primary-divider-color);
        }
    }

    &-ExpandableContentButton {
        padding-block: 12px;
    }

    &-ExpandableContentContent {
        @include desktop {
            padding-block-end: 12px;
        }

        &_isContentExpanded {
            padding-block-end: 12px
        }

        .Note {
            font-weight: 700;

            &-Price {
                padding-inline-start: 3px;
            }

            &-Label {
                font-weight: 400;
                padding-inline-end: 3px;

                &::before {
                    content: "";
                    display: block;
                }
            }
        }
    }
}
