/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.CheckoutShipping {
    &-TextWrapper {
        padding-block-start: 25px;
        padding-block-end: 8px;
    }

    &-DeliveryType {
        width: 404px;
        height: 18px;
        font-style: normal;
        font-weight: 600;
        font-size: 0.9rem;
        line-height: 126%;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        color: $black;
        flex: none;
        order: 0;
        flex-grow: 0;
    }

    &-SelectedCountry,
    &-Zipcode {
        height: initial;

        @media screen and (min-width: 1400px) {
            min-height: 60px;
        }

        .CheckmarkIcon {
            display: none;
        }
    }

    &-Zipcode {
        input {
            padding-block-end: 0.375rem;
            padding-block-start: 1.625rem;
            height: auto;
        }
    }

    &-ShippingPrompt {
        opacity: 0.5;
        font-size: 1rem;
        color: $black;
    }

    &-Country {
        color: var(--color-karaka);
    }

    &-SelectedCountry {
        padding-block-end: 0.375rem;
        padding-block-start: 1.625rem;
        height: auto;
        line-height: 1.25rem;
        color: var(--color-karaka);
    }

    &-CountryPlaceholder {
        inset-block-start: 0.375rem;
    }
}
