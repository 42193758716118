/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.Checkout {
    display: block;

    .CheckoutSuccess {
        &-ContinueButton {
            color: var(--color-white)
        }
    }

    .CheckoutRegistrationLink {
        &-Button {
            width: 172px;
            height: 46px;
            font-weight: 600;
            font-size: 14px;
            line-height: 126%;
            font-family: $font-Klavika;
            text-transform: uppercase;
            letter-spacing: 0.08em;
            background-color: transparent;
            border: 2px solid $default-primary-base-color;
            color: $default-primary-base-color;
            -webkit-transition: background .3s ease-in-out;
            transition: background .3s ease-in-out;
            will-change: background;
            overflow: hidden;

            &:hover {
                background-color: $default-primary-base-color;
                color: $white;
                cursor: pointer;
            }
        }
    }

    .Checkout {
        &-Container {
            &_isDetails {
                min-height: 100vh; // otherwise there is white background under it
                display: flex;
                align-items: center;
                flex-direction: column;
                background-color: var(--light-beige);
                flex: 1;
                padding-inline-end: 0;
                margin-inline-start: 0;
                width: 100%;

                .Checkout {
                    &-Header {
                        margin-inline-start: auto;
                        margin-inline-end: auto;
                        max-width: unset;
                        justify-content: space-around;
                        background-color: var(--light-beige);
                    }

                    &-Step {
                        margin-inline-start: 0;
                        max-width: 564px;
                        padding-block-start: 52px;
                    }
                }

                .Header {
                    width: 100%;
                }
            }
        }
    }
}

.click {
    border: none;
    border-radius: 50%;
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 0;
}

.click.feedback {animation: feedback .6s ease-out;}

@keyframes feedback {
    0% {
        background: rgba(255,255,255,0.2);
        height: 0px;
        width: 0px;
    }

    100% {
        background: rgba(255,255,255,0);
        height: 400px;
        width: 400px;
    }
}
